<template>
  <div class="auth-wrapper auth-v2">
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-large
      rounded="lg"
    >
      <b-row class="auth-inner m-0">
        <b-link class="brand-logo">
          <protex-logo style="width: 150px !important; height: auto" />
        </b-link>
        <!-- #region left side-->
        <b-col
          lg="5"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
          style="background-color: white"
        >
          <b-col sm="12" md="12" lg="12" class="px-xl-2 mx-auto">
            <b-card-title
              class="mb-1 font-weight-bold"
              title-tag="h2"
              style="font-size: 30px; font-weight: 700"
            >
              <b>Multifactor Authentication</b>
            </b-card-title>

            <hr />
            <b-form-group style="padding-top: 20px">
              <div class="d-flex justify-content-between"></div>
              <!-- #Region MFA -->
              <b-form class="auth-login-form mt-2" @submit.prevent="SubmitMFA">
                <b-form-group  style="border-radius: 6px; box-shadow: 0px 24px 48px 0 rgba(41,58,79,0.15) !important;">
                  <div class="d-flex justify-content-between">
                    <label
                      style="color: #596D80; font-weight: 500; font-size:14px"
                    >
                      MFA Verification Code
                    </label>
                  </div>

                  <b-form-input
                    id="mfa-input"
                    style="height:58px; font-size:14px;"
                    v-model="mfa"
                    name="mfa-input-box"
                    placeholder="Verification Code"
                  />
                </b-form-group>

                <b-button
                  id="loginBtn"
                  type="submit"
                  variant="primary"
                  style="margin-top:32px; color: white !important; font-size: 18px; font-weight:500; height:56px;"
                  block
                >
                  Submit Code
                </b-button>
              </b-form>
              <!-- #endRegion MFA -->
              <b-link
                id="forgot-pw"
                :to="{ name: 'auth-forgot-password' }"
                style="
                        margin-top: 10px;
                        font-size: 14px;
                        float: right;
                        color: #7A8B99;
                        font-weight:500;
                        float: right;
                      "
              >
                <span><u>Resend Code?</u></span>
              </b-link>
            </b-form-group>
            <br />
          </b-col>
        </b-col>
        <!-- endregion -->
        <!-- #region right side -->
        <b-col
          v-if="!this.hideRightCol"
          lg="7"
          style="
            padding: 0px;
            background-repeat: no-repeat;
            background-size: cover;
          "
          :style="{ backgroundImage: 'url(' + bgImg + ')' }"
        >
          <img :src="bgImg" style="width: 100%; visibility:hidden" />
        </b-col>
        <!-- endregion -->
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable global-require */

//#region imports
// General
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ProtexLogo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import store from "@/store/index";
import { Auth } from "aws-amplify";
import { loggedOnce } from "@/vue-apollo";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

import {
  getHomeRouteForLoggedInUser,
  getUserRole,
  getUserId,
  getUserName,
  getUserEmail
} from "@/auth/utils";

//Graph QL
import GET_USER_INFO from "@/graphql/login/query/GetUserData.gql";

//Components
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BOverlay
} from "bootstrap-vue";
//#endregion

export default {
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  mixins: [togglePasswordVisibility],

  //#region components
  components: {
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    ProtexLogo
  },
  //#endregion

  //#region data
  data() {
    return {
      user: "",
      mfa: "",
      hideRightCol: false,
      loading: false,
      status: "",
      password: "",
      email: "",
      sideImg: require("@/assets/images/pages/login-image.svg"),
      bgImg: "./bg-login.png",
      required
    };
  },
  //#endregion

  //#region mounted
  mounted() {
    this.$nextTick(() => {
      let width = document.documentElement.clientWidth;
      if (width <= 991) {
        this.hideRightCol = true;
      } else {
        this.hideRightCol = false;
      }
      window.addEventListener("resize", this.getDimensions);
    });
  },
  //#endregion

  //#region computed
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-image.svg");
        return this.sideImg;
      }
      return this.sideImg;
    }
  },
  //#endregion

  //#region methods
  methods: {
    getDimensions() {
      let width = document.documentElement.clientWidth;
      if (width <= 991) {
        this.hideRightCol = true;
      } else {
        this.hideRightCol = false;
      }
    },

    async SubmitMFA() {
      let user = this.$route.params.userAuthObj;
      let code = this.mfa;
      this.loading = true;
      const loggedUser = await Auth.confirmSignIn(user, code, "SMS_MFA");
      loggedOnce.state = true;
      localStorage.setItem(
        "refreshToken",
        JSON.stringify(loggedUser.signInUserSession.refreshToken.token)
      );
      localStorage.setItem(
        "JoT",
        JSON.stringify(loggedUser.signInUserSession.idToken.jwtToken)
      );
      localStorage.setItem("Role", getUserRole());

      const userData = {
        fullName: getUserName(),
        username: getUserName(),
        email: getUserEmail(),
        role: getUserRole(),
        ability: [
          {
            action: "manage",
            subject: "all"
          }
        ],
        accessToken: loggedUser.signInUserSession.accessToken.jwtToken,
        refreshToken: loggedUser.signInUserSession.refreshToken.token
      };

      localStorage.setItem("userData", JSON.stringify(userData));
      localStorage.setItem("userId", getUserId());

      this.$ability.update([
        {
          action: "manage",
          subject: "all"
        }
      ]);
      this.$router.replace(getHomeRouteForLoggedInUser("admin")).then(() => {
        this.loading = false;

        this.intro(userData.fullName.split(" ")[0]);
      });

      Auth.currentSession()
        .then(data => {
          localStorage.setItem("JoT", JSON.stringify(data.idToken.jwtToken));
        })
        .catch(err => console.log(err));

      this.fetchUserAndCompanyInfo()
    },

    intro(data) {
      this.$swal({
        title: `Welcome Back ${data}`,
        icon: "success",
        html: "Logged in successfully.",
        timer: 3000,
        confirmButtonColor: '#00A0F2',
        allowOutsideClick: true,
        customClass: {
          confirmButton: "btn btn-primary"
        },
        buttonsStyling: false
      })
    }
  }
  //#endregion
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

#forgot-pw:hover {
  color: #00a0f3 !important;
}

#sign-up:hover {
  color: #00a0f3 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  font-size: 14px !important;
}
</style>

<style>
</style>
